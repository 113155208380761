import requestManager from "@/utils";
import upLoadRequest from "@/utils/uploadAjax";
console.log('requestManager', requestManager)
// 微信登录
export function onWechatLogin(params) {
    return requestManager
        .request({
            url: "/user/checkAuth",
            method: "post",
            data: params
        });
}
// 发送短信验证码接口
export function onFetchMessage(params) {
    return requestManager
        .request({
            url: "/user/sendSecurityCode",
            method: "post",
            data: params
        });
}
// 注册or 登陆
export function onLoginOrRegister(params) {
    return requestManager
        .request({
            url: "/user/loginOrRegister",
            method: "post",
            data: params
        });
}
// 激活权益卡 绑定权益人接口
export function onActiveRights(params) {
    return requestManager
        .request({
            url: "/order/activationPrivilegeProduct",
            method: "post",
            data: params
        });
}
// 查询登录用户下的权益明细接口
export function onFetchRightsByUser(params) {
    return requestManager
        .request({
            url: "/user/selUserPrivilegeList",
            method: "get",
            params: params
        });
}
// 查询权益详情页接口
export function onFetchRightsDetail(params) {
    return requestManager
        .request({
            url: "/product/selPrivilegeDetial",
            method: "get",
            params: params
        });
}
// 查询权益使用明细接口
export function onFetchRightsUsedInfo(params) {
    return requestManager
        .request({
            url: "/user/selPrivilegeUsedInfo",
            method: "get",
            params: params
        });
}
// 图片上传接口
export function onUploadImage(params) {
    return upLoadRequest
        .request({
            url: "/system/uploadImg",
            method: "post",
            data: params
        });
}
// 预约提交接口
export function onApplyPrivilege(params) {
    return requestManager
        .request({
            url: "/user/applyPrivilege",
            method: "post",
            data: params
        });
}
// 查询我的预约详情接口
export function onFetchDetailByApply(params) {
    return requestManager
        .request({
            url: "/user/selApplyPrivilegeDetail",
            method: "get",
            params: params
        });
}
// 预约取消接口
export function onCancleApply(params) {
    return requestManager
        .request({
            url: "/user/cancelApplyPrivilege",
            method: "post",
            data: params
        });
}
// 根据权益卡编码查询权益卡名称、权益卡展示的协议接口
export function onFetchProductInfo(params) {
    return requestManager
        .request({
            url: "/product/selPrivilegeProductInfo",
            method: "get",
            params: params
        });
}
// 查询我的预约列表接口
export function onFetchMyApplyList(params) {
    return requestManager
        .request({
            url: "/user/selApplyPrivilegeList",
            method: "get",
            params: params
        });
}
// 查询权益对应的药品目录接口
export function onFetchDrugs(params) {
    return requestManager
        .request({
            url: "/product/selDrugListByPrivilegeId",
            method: "get",
            params: params
        });
}
// 添加权益人家属接口
export function onAddPersonFamily(params) {
    return requestManager
        .request({
            url: "/user/addPersonFamily",
            method: "post",
            data: params
        });
}

// 添加权益人家属接口 - 新
export function onAddPersonFamilyNew(params) {
    return requestManager
        .request({
            url: "/user/addPdmPersonFamily",
            method: "post",
            data: params
        });
}
// 我的预约路由接口
export function onDispatchMyApply(params) {
    return requestManager
        .request({
            url: "/user/dispatchApply",
            method: "get",
            params: params
        });
}
// 微信config授权接口
export function onWxConfig(params) {
    return requestManager
        .request({
            url: "/wx/share/wxShareconfig",
            method: "get",
            params: params
        });
}
//查询登录用户信息
export function selUserLoginInfo(params) {
    return requestManager
        .request({
            url: "/user/selUserLoginInfo",
            method: "get",
            params: params
        });
}
//登出
export function loginOut(params) {
    return requestManager
        .request({
            url: "/user/loginOut",
            method: "post",
            data: params
        });
}
//查询单个权益明细接口
export function selUserPrivilegeDetail(params) {
    return requestManager
        .request({
            url: "/user/selUserPrivilegeDetail",
            method: "get",
            params: params
        });
}
//根据token查询付款人信息
export function selPayOrgInfoByToken(params) {
    return requestManager
        .request({
            url: "/orgPay/selPayOrgInfoByToken",
            method: "get",
            params: params
        });
}
//根据wxCode查询付款人信息
export function selPayOrgInfoByWxCode(params) {
    return requestManager
        .request({
            url: "/orgPay/selPayOrgInfoByWxCode",
            method: "get",
            params: params
        });
}
//唤起微信支付接口
export function getPayConfigByWechat(params) {
    return requestManager
        .request({
            url: "/orgPay/getPayConfigByWechat",
            method: "post",
            data: params
        });
}
//唤起微信支付接口
export function getPayConfigByWechatPdm(params) {
    return requestManager
        .request({
            url: "/orgPay/getPayConfigByWechatPdm",
            method: "post",
            data: params
        });
}
//查询微信的支付状态
export function selectPayState(params) {
    return requestManager
        .request({
            url: "/orgPay/selectPayState",
            method: "post",
            data: params
        });
}
//查询会议详情信息
export function selectMeetingById(params) {
    return requestManager
        .request({
            url: "/meeting/selectMeetingById",
            method: "get",
            params: params
        });
}
//登记会议信息
export function registerMeeting(params) {
    return requestManager
        .request({
            url: "/meeting/registerMeeting",
            method: "post",
            data: params
        });
}
//查询机构对应支持的卡种
export function getSupportProductByAdminUserId(params) {
    return requestManager
        .request({
            url: "/view/system/getSupportProductByAdminUserId",
            method: "get",
            params: params
        });
}
export function getSupportProductByOrgId(params) {
    return requestManager
        .request({
            url: "/view/system/getSupportProductByOrgId",
            method: "get",
            params: params
        });
}
//升级插件接口
export function updatePlusPlugin(params) {
    return requestManager
        .request({
            url: "/order/updatePlusPlugin",
            method: "post",
            data: params
        });
}
//累加三年期接口
export function activationThree(params) {
    return requestManager
        .request({
            url: "/order/threeYearActivation",
            method: "post",
            data: params
        });
}
export function selActivation(params) {
    return requestManager
        .request({
            url: "/order/selectActivationCode",
            method: "get",
            params: params
        });
}
export function selPayOrgInfoByOrgId(params) {
    return requestManager
        .request({
            url: "/orgPay/selPayOrgInfoByOrgId",
            method: "get",
            params: params
        });
}
// 提交分配
export function onDistributePdm(params) {
    return requestManager
        .request({
            url: "/user/distributePdm",
            method: "post",
            data: params
        });
}
// 支付接口

export function onSettleInfoSubmit(params) {
    return requestManager
        .request({
            url: "/orgPay/getPayConfigByWechatYuanJiTang",
            method: "post",
            data: params
        });
}

export function onSettleInfoSubmitLongHuShan(params) {
    return requestManager
        .request({
            url: "/orgPay/getPayConfigByWechatLongHuShan",
            method: "post",
            data: params
        });
}