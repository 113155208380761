// const list1 = [{
//     id: 1,
//     className: 'flex-1',
//     name: '体检',
//     label: 2398
// }, {
//     id: 2,
//     className: 'flex-1',
//     name: '降血脂',
//     label: 3784
// }, {
//     id: 3,
//     className: 'flex-1',
//     name: '降血糖',
//     label: 98341
// }];
const list3 = [{
    id: 1,
    className: 'flex-1',
    name: '九路',
    label: 120,
    tag: ''
}, {
    id: 2,
    className: 'flex-1',
    name: '三宝',
    label: 2000,
    tag: ''
}, {
    id: 3,
    className: 'flex-1',
    name: '五福',
    label: 1200,
    tag: ''
},{
    id: 4,
    className: 'flex-1',
    name: '祈福',
    label: 22000,
    tag: ''
}, {
    id: 5,
    className: 'flex-1',
    name: '墨宝',
    label: 200000,
    tag: ''
}]
// const list2 = [{
//     id: 7,
//     className: 'flex-1',
//     name: '降血压',
//     label: 3948
// }, {
//     id: 8,
//     className: 'flex-1',
//     name: '消化系统',
//     label: 98341
// }, {
//     id: 9,
//     className: 'flex-1',
//     name: '心脏彩超',
//     label: 3948
// }];
export const list = [list3];
export const expendList = [...list3];
export const item = JSON.parse(JSON.stringify({
    personName: "", // 客户姓名
    remark: "",
    productList: [], // 产品列表
    sum: 0, // 总计
  }));