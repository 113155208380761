<template>
  <div class="main">
    <div class="content">
      <div class="manage-info manage-info-repeat" v-for="(item, index) in form.itemList" :key="index">
        <div class="title-wrap" v-if="index == 0">
          <span />个人信息
        </div>
        <div class="manage-info_content">
          <div class="input-wrap">
            <div class="key"><span class="requeird">*</span>付款人姓名</div>
            <van-field v-model="item.personName" placeholder="请输入付款人姓名" />
          </div>

          <!--     <div class="input-wrap">
            <div class="key">付款人手机号</div>
            <van-field v-model="item.personPhone" placeholder="" />
          </div> -->
<!-- 
          <div class="input-wrap">
            <div class="key"><span class="requeird">*</span>机构名称</div>
            <div class="val">{{ selPayOrgInfo.saleOrgName }}</div>
          </div> -->
          <div class="input-wrap input-vertical">
            <div class="key" style="margin-bottom: 10px;"><span class="requeird">*</span>项目列表</div>
            <div class="project-list">
              <ul v-for="(listItem, listIndex) in list" :key="listIndex">
                <li v-for="projectItem in listItem" :key="projectItem.id" :class="[
                  { active: item.productList.find(item => item.productId == projectItem.id) },
                ]" @click="onHandleChoice(index, projectItem)">
                  <p class="title">{{ projectItem.name }}</p>
                  <p class="title">{{ projectItem.tag }}</p>
                  <p class="middle"></p>
                  <p class="money">￥{{ projectItem.label }}</p>
                  <div class="item-success" v-if="item.productList.find(item => item.productId == projectItem.id)" />
                </li>
              </ul>
            </div>
          </div>

          <div class="input-wrap">
            <div class="key">备注</div>
            <van-field v-model="item.remark" placeholder="请填写备注" />
          </div>

          <div class="money" v-if="item.productList.length">
            <div class="h1" style="margin-bottom:15px">项目费用</div>
            <!--  <div v-for="(expendItem, expendIndex) in expendList" :key="expendIndex" class="money-item">
              <div v-if="item.productList.find(item=>item.productId==expendItem.id)" class="item">
                <span class="name">{{ expendItem.name }}{{ expendItem.tag }}</span>
                <div class="tool">
                  <span class="money">￥{{ expendItem.label }}</span>
                  <van-stepper v-model="value" size="small" />
                </div>

              </div>
            </div> -->
            <div v-for="(productItem) in item.productList" :key="productItem.productId" class="money-item">
              <div class="item">
                <span class="name">{{ productItem.info.name }}{{ productItem.info.tag }}</span>
                <div class="tool">
                  <span class="money">￥{{ productItem.info.label * productItem.productCount }}</span>
                  <van-stepper v-model="productItem.productCount" size="small" />
                </div>
              </div>

            </div>
          </div>

          <div class="money-sum">
            <span class="key">总计</span>
            <span class="val">￥{{ sumMoney(item) }}</span>
          </div>
        </div>

      </div>
      <div class="footer">
        <div class="sub-btn" @click="onHandleSubmit">
          确认并付款
        </div>
      </div>
    </div>



    <!-- 底部 -->
    <!--    <div class="info-footer flex flex-between flex-align-center">
        <dl>
          <dt>总计</dt>
          <dd>￥{{ sum }}</dd>
        </dl>
        <div class="info-footer_right">
          <div class="pay-now" @click="onHandleSubmit">立即支付</div>
        </div>
      </div> -->
    <van-overlay :show="loading">
      <van-loading size="40" vertical class="loadingDiv">支付中...</van-loading>
    </van-overlay>
  </div>
</template>
<script>
import { Toast } from "vant";
import { list, expendList, item } from "./config";
import { onSettleInfoSubmitLongHuShan, selPayOrgInfoByOrgId, } from "@/api";
import { weixinInit } from "@/utils/wechatUtil.js";
export default {
  data() {
    return {
      title: "请选择日期",
      isLink: true,
      show: false,
      loading: false,
      // wxCode: code,
      urlString: "",
      list,
      selPayOrgInfo: {},
      expendList,
      form: {
        payPersonName: "", // 业务员姓名
        payPersonNo: "", // 业务员工号
        payPersonPhone: "", // 业务员手机号
        itemList: [JSON.parse(JSON.stringify(item))],
      },
      sum: 0, // 总计
      orgId: "",
      currentItem: null,
      currentIndex: 0,
    };
  },

  created() {
    var currentDate = new Date();
    // 将日期增加一天
    currentDate.setDate(currentDate.getDate() + 1);
    this.minDate = currentDate;

    this.orgId = localStorage.getItem("orgId") || "";
    this.urlString = window.location.href.split("#")[0];

    const form = localStorage.getItem("pdm_cache");

    if (form) {
      this.form = JSON.parse(form);
      this.onGetTotalSum();
    }
    this.wxConfigInit();
   // this.getPayOrgInfo()


  },

  methods: {

    sumMoney(item) {
      let sum = 0;
      if (item && item.productList && item.productList.length > 0) {
        item.productList.forEach(item => {
          sum += item.productCount * item.info?.label;
        })
      }
      return sum;

    },
    getPayOrgInfo() {
      let code = localStorage.getItem("code");
      let orgIdParam = this.orgId;
      if (code) {
        selPayOrgInfoByOrgId({
          orgId: orgIdParam,
        }).then((res) => {
          if (res.code == 200) {
            this.selPayOrgInfo = res.data;
          }
        })

      }

    },

    onHandleChoiceDate(index) {
      this.currentIndex = index;
      this.currentItem = this.form.itemList[index];
      this.show = true;
    },
    onPad(num) {
      return num < 10 ? "0" + num : num;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${this.onPad(
        date.getMonth() + 1
      )}-${this.onPad(date.getDate())}`;
    },
    onConfirm(date) {
      this.show = false;
      // console.log("this.formatDate(date)", this.formatDate(date));
      // this.date = this.formatDate(date);
      this.form.itemList[this.currentIndex]["checkStartTime"] =
        this.formatDate(date);
      this.isLink = false;
      this.title = this.form.itemList[this.currentIndex]["checkStartTime"];
    },
    async wxConfigInit() {
      let weixinShare = {
        publicType: 1,
        url: this.urlString,
      };
      await weixinInit(weixinShare);
    },
    //唤起微信支付
    async weixinPay(weixinPayParam) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        this.onBridgeReady(weixinPayParam);
      }
    },
    async onBridgeReady(weixinPayParam) {
      let payNoDiy = weixinPayParam.payNo;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: weixinPayParam.appId,
          timeStamp: weixinPayParam.timeStamp,
          nonceStr: weixinPayParam.nonceStr,
          package: weixinPayParam.packages,
          signType: weixinPayParam.signType,
          paySign: weixinPayParam.paySign,
        },
        function (res) {
          localStorage.removeItem("pdm_cache");
          window.location.href =
            "https://yisheng.healthmore.net/longHuShanInfoSuccess?payNo=" +
            payNoDiy;
          //this.$router.push(`/paysuccess?payNo=${payNoDiy}`);
        }
      );
    },
    // 选择
    onHandleChoice(index, item) {
      /*     console.log("我是第" + index + "个");
          console.log("item", index, item);
          console.log(this.form.itemList[index]); */
    try {
      const selectIndex = this.form.itemList[index]["productList"].findIndex((product) => product.productId == item.id);
      console.log("selectIndex", selectIndex);
      if (selectIndex != -1) {
        this.form.itemList[index]["productList"].splice(selectIndex, 1);
        //  this.form.itemList[index]["sum"] -= item.label;
      } else {
        this.form.itemList[index]["productList"].push({
          productId: item.id,
          productCount: 1,
          info: item
        }); 
        // this.form.itemList[index]["sum"] += item.label;
      }
      console.log("sele", this.form.itemList[index]["productList"]);
      this.onGetTotalSum();
      
    } catch (error) {
      
    }
    },
    // 计算总金额
    onGetTotalSum() {
      let sum = 0;
      this.form.itemList.forEach((item) => {
        item.productList.forEach((innerItem) => {
          const selectedItem = this.expendList.filter(
            (expendedItem) => expendedItem.id == innerItem
          );
          console.log("selectedItem", selectedItem);
          sum += selectedItem[0].label;
        });
      });
      this.sum = sum;
    },
    // 去支付
    onHandleSubmit() {
      const list = this.form.itemList;
      for (let i = 0; i < list.length; i++) {
        if (!list[i].personName) {
          Toast("请填写客户姓名");
          return false;
        }
        /*    if (!list[i].personIdCard) {
             Toast("请填写客户客户身份证号");
             return false;
           }
           if (!list[i].personPhone) {
             Toast("请填写客户手机号");
             return false;
           }
           if (list[i].personPhone.length != 11) {
             Toast("请输入正确的客户手机号");
             return;
           }
           if (!list[i].checkStartTime) {
             Toast("请选择检查日期");
             return;
           } */
        if (!list[i].productList.length) {
          Toast("请至少选择一个项目");
          return false;
        }
      }
      this.onHandleFinalSumit();
    },
    formatPayParams(){
      let code = localStorage.getItem("code") || "";
      return{
        wxCode: code,
        saleOrgId: this.orgId,
        itemList: this.form.itemList.map(item => {
          return {
            "personName": item.personName,
            "remark": item.remark,
            "productList": item.productList.map(item2 => {
              return {
                "productId": item2.productId,
                "productCount": item2.productCount
              }
            })
          }
        })
      };
    },
    // 提交接口
    async onHandleFinalSumit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let values=this.formatPayParams();
      console.log('values',values)
      try {
        const res = await onSettleInfoSubmitLongHuShan(values)
        console.log("res", res);
        if (res.code == 200) {
          this.weixinPay(res.data);
        } else {
          this.$toast(res.msg);
          this.loading = false;
        }
      } catch (err) {
        console.log("err", err);
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@font-face {
  font-family: "SongtiSC";
  src: url("../../assets/pdmInfo/fonts/song.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  /* 可选，用于字体加载期间的替代字体 */
}

@font-face {
  font-family: "songCN";
  src: url("../../assets/pdmInfo/fonts/songCN.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  /* 可选，用于字体加载期间的替代字体 */
}

.main {
  padding: 40px 20px 40px;
  background-image: url("../../assets/pay-bg.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  font-size: 14px;
  /deep/ .van-loading--vertical {
    padding-top:25vh;
  }

}

.content {
  background-color: #fff;
  box-shadow: 0px 0px 17px 0px #00000012;
  border-radius: 16px;
  padding: 30px 15px 20px;


}

.title-wrap {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #000;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left:-15px;

  span {
    display: inline-block;
    width: 5px;
    height: 16px;
    background-color: #EFCA66;
    margin-right: 14px;
  }
}

.h1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.2px;
  color: #000000;
}

.input-wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F8F9FB;
  margin-bottom: 10px;

  .key {
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.2px;
    color: #000000;
  }

  .val {
    text-align: right;
    flex: 1;
    padding: 0.26667rem 0.42667rem;
    color: #323233;
    font-size: 0.37333rem;
    line-height: 0.64rem;
  }

  /deep/ .van-cell {
    flex: 1;
  }

  /deep/ .van-field__control {
    text-align: right;
  }
}

.input-vertical {
  display: block;
  border: none;
  margin-top:20px;
}

.project-list {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;

    li {
      width: 95px;
      height: 64px;
      border: 1px solid #F8F9FB;
      position: relative;
      top: 0;
      left: 0;
      border-radius: 10px;
      margin: 5px;
      background-color: #F8F9FB;
      transition: all ease-out 120ms;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 10px 0;

      .title {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #000;

      }

      .money {
        font-size: 11px;
        font-weight: 500;
        line-height: 15.4px;
        text-align: center;
        color: #FF3C1C;
      }

      .item-success {
        position: absolute;
        top: -1px;
        right: -1px;
        width: 17px;
        height: 17px;
        background-image: url('../../assets/icon-selected.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      &.active {
        border-color: #EFCA66;
      }
    }
  }
}

.money-item {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      font-size: 13px;
      font-weight: 400;
      text-align: left;
      color: #1E1E1E;
    }

    .money {
      font-size: 13px;
      font-weight: 600;
      color: #FF3C1C;
      margin-right: 20px;
    }

    .tool {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/ .van-stepper{
    transform: scale(.9);
    margin-right: -5px;
  }
    }
  }

}

.money-sum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 25px;
  border-top: 1px solid #F8F9FB;
  margin-top:10px;


  .key {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
  }

  .val {
    font-size: 16px;
    font-weight: 600;
    color: #FF3C1C;

  }
}

.sub-btn {
  width: 100%;
  height: 45px;
  line-height: 45px;
  border-radius: 100px;
  background-color: #EFCA66;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.requeird{
  color:#EE0F2A;

}

.footer {



}
</style>
